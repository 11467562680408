@import './extra';

// Colors
$color-main-dark: #16082C;
$color-main: #77729F;
$color-main-light: #7554A8;
$color-main-lighter: #B898CC;

$color-border: #4F415E;

$footer-bg: #1F0D3C;
$color-footer-text: #B898CC;

$color-purple: #5801E3;
$color-red: #FC4646;
$color-green: #42E59A;
$color-pink: #FF457C;
$color-green: #42E59A;
$color-black: #000000;
$color-gray: #A0A0A0;
$color-light-gray: #D0C4E6;
$color-dark-blue: #40A5FF;
$color-blue: #40A5FF;
$color-light-blue: #4ED7D9;
$color-orange: #FB9154;
$color-magenta: #FF457C;
$color-indigo: #5801E3;
$color-yellow: #FFDB55;
$color-off-white: #E7E2F0;
$color-beige: #f8dda4;

$color-input: #4F415E;

// Button style
$button-standard: 100px;
$button-large: 250px;
$button-small: 70px;
$button-dash-width: 40%;

// Fonts
$font-heading: 'Quitador Sans';
$font-body: 'Glober';
$font-size-default: 16px;

$header-height: 10em;
$header-height-mobile: 4em;

// Page layout
$layout-width: 1200px;
$layout-readable-width: 740px;

// Breakpoints
$tablet-landscape: 1024px;
$tablet: 768px;
$mobile: 600px;

// Transition-related
$base-speed: 200ms;
$ease: cubic-bezier(0.500, 0.500, 0.000, 1.000);
