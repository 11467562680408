$color-background: rgba(0, 0, 0, 0.5);
$color-background-dark: $color-main-dark;

$color-default: #D0C4E6;
$color-comment: #81719C;
$color-keyword: #FF457C;
$color-literal-string: #42E59A;
$color-literal-number: #42E59A;
$color-error: #FC4646;
$color-operator: #40A5FF;

.highlighter-rouge {
  background: rgba($color-main-light, 0.15);
  color: $color-main-dark;
  border: 0;
}

strong code {
  font-weight: bold;
}

code {
  background: rgba($color-main-light, 0.15);
  color: $color-main-dark;
}

.highlight {
  code {
    background-color: inherit;
    color: inherit;
  }
}

figure.highlight, pre.highlight { padding: 10px; margin: 0; font-family: monospace; overflow-x: auto; }
.highlight .hll { background-color: $color-background; }
.highlight:not(pre)  { box-shadow: 0 2px 6px rgba(black, 0.5); background: $color-background-dark; border-radius: 5px; color: $color-default; }
.highlight .c { color: $color-comment; } /* Comment */
.highlight .err { color: $color-default; border: 1px solid $color-error; } /* Error */
.highlight .esc { color: $color-default; } /* Escape */
.highlight .g { color: $color-default; } /* Generic */
.highlight .k { color: $color-keyword; } /* Keyword */
.highlight .l { color: $color-default; } /* Literal */
.highlight .n { color: $color-default; } /* Name */
.highlight .o { color: $color-operator; } /* Operator */
.highlight .x { color: $color-default; } /* Other */
.highlight .p { color: $color-default; } /* Punctuation */
.highlight .ch { color: $color-comment; } /* Comment.Hashbang */
.highlight .cm { color: $color-comment; } /* Comment.Multiline */
.highlight .cp { color: $color-comment; } /* Comment.Preproc */
.highlight .cpf { color: $color-comment; } /* Comment.PreprocFile */
.highlight .c1 { color: $color-comment; } /* Comment.Single */
.highlight .cs { color: $color-literal-string; font-weight: bold; } /* Comment.Special */
.highlight .gd { color: $color-literal-string; } /* Generic.Deleted */
.highlight .ge { color: $color-default; font-style: italic; } /* Generic.Emph */
.highlight .gr { color: $color-error;} /* Generic.Error */
.highlight .gh { color: $color-comment; font-weight: bold; } /* Generic.Heading */
.highlight .gi { color: $color-keyword; } /* Generic.Inserted */
.highlight .go { color: #888888; } /* Generic.Output */
.highlight .gp { color: $color-comment; font-weight: bold; } /* Generic.Prompt */
.highlight .gs { color: $color-default; font-weight: bold; } /* Generic.Strong */
.highlight .gu { color: #800080; font-weight: bold; } /* Generic.Subheading */
.highlight .gt { color: #0044DD; } /* Generic.Traceback */
.highlight .kc { color: $color-keyword; } /* Keyword.Constant */
.highlight .kd { color: $color-keyword; } /* Keyword.Declaration */
.highlight .kn { color: $color-literal-number; } /* Keyword.Namespace */
.highlight .kp { color: $color-keyword; } /* Keyword.Pseudo */
.highlight .kr { color: $color-keyword; } /* Keyword.Reserved */
.highlight .kt { color: $color-keyword ;} /* Keyword.Type */
.highlight .ld { color: $color-default; } /* Literal.Date */
.highlight .m { color: $color-literal-number; } /* Literal.Number */
.highlight .s { color: $color-literal-string; } /* Literal.String */
.highlight .na { color: $color-beige; } /* Name.Attribute */
.highlight .nb { color: $color-literal-number; } /* Name.Builtin */
.highlight .nc { color: #00cdcd; } /* Name.Class */
.highlight .no { color: $color-default; } /* Name.Constant */
.highlight .nd { color: $color-default; } /* Name.Decorator */
.highlight .ni { color: $color-default; } /* Name.Entity */
.highlight .ne { color: #666699; font-weight: bold; } /* Name.Exception */
.highlight .nf { color: $color-default; } /* Name.Function */
.highlight .nl { color: $color-default; } /* Name.Label */
.highlight .nn { color: $color-default; } /* Name.Namespace */
.highlight .nx { color: $color-default; } /* Name.Other */
.highlight .py { color: $color-default; } /* Name.Property */
.highlight .nt { color: $color-magenta; } /* Name.Tag */
.highlight .nv { color: #00cdcd; } /* Name.Variable */
.highlight .ow { color: $color-keyword; } /* Operator.Word */
.highlight .w { color: $color-default; } /* Text.Whitespace */
.highlight .mb { color: $color-literal-number; } /* Literal.Number.Bin */
.highlight .mf { color: $color-literal-number; } /* Literal.Number.Float */
.highlight .mh { color: $color-literal-number; } /* Literal.Number.Hex */
.highlight .mi { color: $color-literal-number; } /* Literal.Number.Integer */
.highlight .mo { color: $color-literal-number; } /* Literal.Number.Oct */
.highlight .sa { color: $color-literal-string } /* Literal.String.Affix */
.highlight .sb { color: $color-literal-string } /* Literal.String.Backtick */
.highlight .sc { color: $color-literal-string } /* Literal.String.Char */
.highlight .dl { color: $color-literal-string } /* Literal.String.Delimiter */
.highlight .sd { color: $color-literal-string } /* Literal.String.Doc */
.highlight .s2 { color: $color-literal-string } /* Literal.String.Double */
.highlight .se { color: $color-literal-string } /* Literal.String.Escape */
.highlight .sh { color: $color-literal-string } /* Literal.String.Heredoc */
.highlight .si { color: $color-literal-string } /* Literal.String.Interpol */
.highlight .sx { color: $color-literal-string } /* Literal.String.Other */
.highlight .sr { color: $color-literal-string } /* Literal.String.Regex */
.highlight .s1 { color: $color-literal-string } /* Literal.String.Single */
.highlight .ss { color: $color-literal-string } /* Literal.String.Symbol */
.highlight .bp { color: $color-literal-number; } /* Name.Builtin.Pseudo */
.highlight .fm { color: $color-default; } /* Name.Function.Magic */
.highlight .vc { color: #00cdcd; } /* Name.Variable.Class */
.highlight .vg { color: #00cdcd; } /* Name.Variable.Global */
.highlight .vi { color: #00cdcd; } /* Name.Variable.Instance */
.highlight .vm { color: #00cdcd; } /* Name.Variable.Magic */
.highlight .il { color: $color-literal-number; } /* Literal.Number.Integer.Long */